import { Datagrid, List, TextField } from "react-admin";

export const PricesCategoryList = () => (
  <List sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="category" label="Назва категорії" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

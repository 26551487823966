import {
  Datagrid,
  ImageField,
  List,
  NumberField,
  RichTextField,
  TextField,
} from "react-admin";

export const SpecialistsList = () => (
  <List sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <ImageField
        source="image"
        label="Фотографія"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <TextField source="name" label="Ім'я та прізвище" />
      <RichTextField source="about" label="Спеціальність" />
      <RichTextField source="description" fullWidth label="Опис" />
      <NumberField source="order" label="Порядок" defaultValue={0} />
    </Datagrid>
  </List>
);

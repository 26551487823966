import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

export const PricesCategoryEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth />
      <TextInput source="category" fullWidth label="Назва категорії" />
      <TextInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Edit>
);

import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Admin,
  DataProvider,
  Loading,
  Resource,
} from "react-admin";
import { ServiceList } from "./modules/services/components/services-list.component";
import { ServiceEdit } from "./modules/services/components/services-edit.component";
import { ServiceCategoryList } from "./modules/services_edit/components/services-category-list.component";
import { ServiceCategoryEdit } from "./modules/services_edit/components/services-category-edit.component";
import { ServiceCreate } from "./modules/services/components/services-create.component";
import { ServiceCategoryCreate } from "./modules/services_edit/components/services-category-create.component";
import { buildDataProvider } from "./core/data-provider";
import { i18nProvider } from "./core/i18n";
import { authProvider } from "./core/auth-provider";
import { theme } from "./core/theme";
import { BrandsList } from "./modules/brands/components/brands-list.component";
import { BrandsEdit } from "./modules/brands/components/brands-edit.component";
import { BrandsCreate } from "./modules/brands/components/brands-create.component";
import { CertificatesList } from "./modules/certificates/components/certificates-list.component";
import { CertificatesEdit } from "./modules/certificates/components/certificates-edit.component";
import { CertificatesCreate } from "./modules/certificates/components/certificates-create.component";
import { SpecialistsList } from "./modules/specialists/components/specialists-list.component";
import { SpecialistsEdit } from "./modules/specialists/components/specialists-edit.component";
import { SpecialistsCreate } from "./modules/specialists/components/specialists-create.component";
import { PricesList } from "./modules/prices/components/prices-list.component";
import { PricesEdit } from "./modules/prices/components/prices-edit.component";
import { PricesCreate } from "./modules/prices/components/prices-create.component";
import { SocialsList } from "./modules/socials/components/socials-list.component";
import { SocialsEdit } from "./modules/socials/components/socials-edit.component";
import { SocialsCreate } from "./modules/socials/components/socials-create.component";
import { InstagramList } from "./modules/instagram/components/instagram-list.component";
import { InstagramEdit } from "./modules/instagram/components/instagram-edit.component";
import { InstagramCreate } from "./modules/instagram/components/instagram-create.component";
import { PricesCategoryList } from "./modules/prices_category/components/prices_category-list.component";
import { PricesCategoryEdit } from "./modules/prices_category/components/prices_category-edit.component";
import { PricesCategoryCreate } from "./modules/prices_category/components/prices_category-create.component";
import { PricesSubCategoryList } from "./modules/prices_sub_category/components/prices_sub_category-list.component";
import { PricesSubCategoryEdit } from "./modules/prices_sub_category/components/prices_sub_category-edit.component";
import { PricesSubCategoryCreate } from "./modules/prices_sub_category/components/prices_sub_category-create.component";

function App() {
  const [dataProvider, setDataProvider] =
    useState<DataProvider<string> | null>(null);
  useEffect(() => {
    const getDataProvider = async () => {
      const dp = await buildDataProvider();

      setDataProvider(dp);
    };

    getDataProvider();
  }, []);

  if (!dataProvider) {
    return <Loading />;
  }

  return (
    <>
      <CssBaseline />
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        requireAuth
        theme={theme}
      >
        <Resource
          name="services"
          list={ServiceList}
          edit={ServiceEdit}
          create={ServiceCreate}
          options={{label: 'Послуги' }}
        ></Resource>
        <Resource
          name="services_category"
          list={ServiceCategoryList}
          edit={ServiceCategoryEdit}
          create={ServiceCategoryCreate}
          options={{label: 'Категорії послуг' }}
        ></Resource>
        <Resource
          name="brands"
          list={BrandsList}
          edit={BrandsEdit}
          create={BrandsCreate}
          options={{label: 'Бренди' }}
        ></Resource>
        <Resource
          name="certificates"
          list={CertificatesList}
          edit={CertificatesEdit}
          create={CertificatesCreate}
          options={{label: 'Сертифікати' }}
        ></Resource>
        <Resource
          name="specialists"
          list={SpecialistsList}
          edit={SpecialistsEdit}
          create={SpecialistsCreate}
          options={{label: 'Спеціалісти' }}
        ></Resource>
        <Resource
          name="prices"
          list={PricesList}
          edit={PricesEdit}
          create={PricesCreate}
          options={{label: 'Ціни' }}
        ></Resource>
        <Resource
          name="prices_category"
          list={PricesCategoryList}
          edit={PricesCategoryEdit}
          create={PricesCategoryCreate}
          options={{label: 'Ціни категорії' }}
        ></Resource>
        <Resource
          name="prices_sub_category"
          list={PricesSubCategoryList}
          edit={PricesSubCategoryEdit}
          create={PricesSubCategoryCreate}
          options={{label: 'Ціни підкатегорії' }}
        ></Resource>
        <Resource
          name="contacts"
          list={SocialsList}
          edit={SocialsEdit}
          create={SocialsCreate}
          options={{label: 'Соціалки' }}
        ></Resource>
        <Resource
          name="instagram"
          list={InstagramList}
          edit={InstagramEdit}
          create={InstagramCreate}
          options={{label: 'Інстаграм' }}
        ></Resource>
      </Admin>
    </>
  );
}

export default App;

import { Create, SimpleForm, TextInput } from "react-admin";

export const PricesCategoryCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="category" fullWidth label="Назва категорії" />
      <TextInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Create>
);

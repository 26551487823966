import {
  Datagrid,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  RichTextField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const filters = [
  <TextInput alwaysOn source="title" label="Послуга" />,
  <ReferenceInput source="category_id" reference="prices_category" alwaysOn>
    <SelectInput optionText="category" fullWidth label="Категорія" sx={{ margin: "0" }} />
  </ReferenceInput>,
];

export const PricesList = () => (
  <List sort={{ field: "order", order: "ASC" }} filters={filters}>
    <Datagrid rowClick="edit">
      <RichTextField source="title" fullWidth label="Послуга" />
      <ReferenceField
        source="category_id"
        reference="prices_category"
        label="Категорія"
      >
        <TextField source="category" label="Категорія" />
      </ReferenceField>
      <ReferenceField
        source="sub_category_id"
        reference="prices_sub_category"
        label="Підкатегорія"
      >
        <TextField source="sub_category" label="Підкатегорія" />
      </ReferenceField>
      <NumberField source="price" fullWidth label="Ціна послуги" />
      <NumberField source="order" fullWidth label="Порядок" defaultValue={0} />
    </Datagrid>
  </List>
);

import { CloudinaryInputUI } from "@app/common/components/cloudinary-input-ui/cloudinary-input-ui.component";
import { CloudinarySignatureQuery } from "@app/core/types";
import { FC, useState } from "react";
import { InputProps, useInput, useNotify } from "react-admin";
import axios, { AxiosError } from "axios";
import { CloudinaryUploadDTO } from "@app/common/components/cloudinary-input/cloudinary-upload.dto";
import CloudinarySignatureQueryGql from "@app/core/graphql/cloudinary-signature.gql";
import { apolloClient } from "@app/core/apollo-client";

export const CloudinaryInput: FC<InputProps> = (props) => {
  const { label, source } = props;
  const computedLabel = String(label) ?? source;
  const [isloadingImage, setIsLoadingImage] = useState(false);

  const notify = useNotify();

  const {
    field: { onChange, value },
  } = useInput(props);

  const onImageSelected = async (image: File) => {
    const { data } = await apolloClient.query<CloudinarySignatureQuery>({
      query: CloudinarySignatureQueryGql,
      fetchPolicy: "no-cache",
    });

    if (!data?.cloudinarySignature) {
      return;
    }

    const { cloudName, apiKey, publicId, signature, timestamp } =
      data.cloudinarySignature;

    const url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;
    const formData = new FormData();

    formData.append("file", image);
    formData.append("api_key", apiKey);
    formData.append("public_id", publicId);
    formData.append("signature", signature);
    formData.append("timestamp", String(timestamp));
    formData.append("folder", "dr-serdiuk");

    let dataUpload: CloudinaryUploadDTO;

    try {
      setIsLoadingImage(true);
      const response = await axios.post<CloudinaryUploadDTO>(url, formData);
      dataUpload = response.data;
      setIsLoadingImage(false);
    } catch (error) {
      setIsLoadingImage(false);
      return notify((error as AxiosError).message);
    }

    onChange(dataUpload!.secure_url);
  };

  return (
    <CloudinaryInputUI
      label={computedLabel}
      value={value}
      disabled={isloadingImage}
      onImageSelected={onImageSelected}
    />
  );
};

import { Create, SimpleForm, TextInput } from "react-admin";

export const SocialsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth label="ID" />
      <TextInput source="name" fullWidth label="Назва" />
      <TextInput source="type" fullWidth label="Тип" />
      <TextInput source="link" fullWidth label="Посилання" />
    </SimpleForm>
  </Create>
);

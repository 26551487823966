import { Datagrid, List, TextField } from "react-admin";

export const SocialsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" disabled fullWidth label="ID" />
      <TextField source="name" fullWidth label="Назва" />
      <TextField source="type" fullWidth label="Тип" />
      <TextField source="link" fullWidth label="Посилання" />
    </Datagrid>
  </List>
);

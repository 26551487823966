import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const ServiceEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth />
      <TextInput source="name" fullWidth label="Назва послуги" />
      <RichTextInput source="details_content" fullWidth label="Опис послуги" />
      <TextInput source="url" fullWidth label="Посилання на послуги" />
      <ReferenceInput
        source="category_id"
        reference="services_category"
        fullWidth
        label="Категорія послуги"
      >
        <SelectInput optionText="name" label="Категорія послуги" fullWidth />
      </ReferenceInput>
      <TextInput source="meta_title" fullWidth label="Метадані title" />
      <TextInput source="meta_description" fullWidth label="Метадані description" />
      <CloudinaryInput source="image_details" label="Зображення в деталях послуги" />
      <TextInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Edit>
);

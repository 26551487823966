import { Datagrid, List, ReferenceField, TextField } from "react-admin";

export const PricesSubCategoryList = () => (
  <List sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="sub_category" label="Назва підкатегорії" />
      <ReferenceField source="category_id" reference="prices_category">
        <TextField source="category" />
      </ReferenceField>
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

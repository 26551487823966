import { Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const SpecialistsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth label="ID" />
      <TextInput source="name" fullWidth label="Ім'я та прізвище" />
      <RichTextInput source="about" fullWidth label="Спеціальність" />
      <RichTextInput source="description" fullWidth label="Опис" />
      <NumberInput source="order" label="Порядок відображення (від 0)" defaultValue={0} />
      <CloudinaryInput source="image" label="Фотографія" />
    </SimpleForm>
  </Edit>
);

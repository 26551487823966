import { BooleanInput, Create, NumberInput, SimpleForm } from "react-admin";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const CertificatesCreate = () => (
  <Create>
    <SimpleForm>
      <CloudinaryInput source="image" label="Зображення сертифікату" />
      <BooleanInput source="is_show_main" fullWidth label="Відображати на головній сторінці" />
      <NumberInput source="order" fullWidth label="Порядок"  defaultValue={0}/>
    </SimpleForm>
  </Create>
);

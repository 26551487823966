import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const InstagramCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="instagram_href" fullWidth label="Посилання" />
      <CloudinaryInput source="instagram_image" label="Зображення" />
      <NumberInput source="order" fullWidth label="Порядок"  defaultValue={0}/>
    </SimpleForm>
  </Create>
);

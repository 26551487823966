import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from "react-admin";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const CertificatesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth label="ID" />
      <CloudinaryInput source="image" label="Зображення сертифікату" />
      <BooleanInput source="is_show_main" fullWidth label="Відображати на головній сторінці" />
      <NumberInput source="order" fullWidth label="Порядок" defaultValue={0}/>
    </SimpleForm>
  </Edit>
);

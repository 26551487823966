import { Create, SimpleForm } from "react-admin";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const BrandsCreate = () => (
  <Create>
    <SimpleForm>
      <CloudinaryInput source="image" label="Зображення бренду" />
    </SimpleForm>
  </Create>
);

import { Edit, SimpleForm, TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const ServiceCategoryEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth label="ID послуги" />
      <TextInput source="name" fullWidth label="Назва послуги" />
      <TextInput source="url" fullWidth label="Посилання на послуги" />
      <RichTextInput source="details_content" fullWidth label="Опис послуги" />
      <TextInput source="meta_title" fullWidth label="Метадані title" />
      <TextInput source="meta_description" fullWidth label="Метадані description" />
      <CloudinaryInput source="image_menu" label="Зображення в меню" />
      <CloudinaryInput source="image_details" label="Зображення в деталях послуги" />
      <CloudinaryInput source="image_main_page" label="Зображення на головній сторінці" />
      <TextInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Edit>
);

import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const PricesSubCategoryCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="sub_category" fullWidth label="Назва підкатегорії" />
      <ReferenceInput source="category_id" reference="prices_category">
        <SelectInput optionText="category" />
      </ReferenceInput>
      <TextInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Create>
);

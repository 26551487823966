import {
  Datagrid,
  ImageField,
  List,
  NumberField,
  TextField,
} from "react-admin";

export const InstagramList = () => (
  <List sort={{ field: "order", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" fullWidth label="Id" disabled />
      <TextField source="instagram_href" fullWidth label="Посилання" />
      <ImageField
        source="instagram_image"
        label="Зображення"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <NumberField source="order" fullWidth label="Порядок" defaultValue={0} />
    </Datagrid>
  </List>
);

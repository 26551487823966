import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
} from "@mui/material";
import { FC, useRef } from "react";

interface CloudinaryInputUIProps {
  label: string;
  disabled: boolean;
  onImageSelected: (image: File) => void;
  value?: string;
  fitImage?: boolean;
}

export const CloudinaryInputUI: FC<CloudinaryInputUIProps> = ({
  label,
  disabled,
  onImageSelected,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) {
      return;
    }

    const [file] = Array.from(e.target.files);

    if (!file) {
      return;
    }

    onImageSelected(file);
  };

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <div style={{ marginBottom: 20, width: "100%" }}>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={onFileInputChange}
      />
      <Card variant="outlined">
        <CardHeader title={label} />
        <CardContent >
          {value && !disabled ? (
            <div style={{background: '#fff', width: '300px', height: "300px", border: "1px solid #fbbf24"}}>
              <img
                src={value}
                alt=""
                style={{ objectFit: "contain", cursor: "pointer", margin: "-1px" }}
                width={300}
                height={300}
                onClick={onUploadClick}
              />
            </div>
          ) : (
            <Skeleton
              variant="rectangular"
              width={384}
              height={240}
              onClick={() => !disabled && onUploadClick()}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

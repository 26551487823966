import { Datagrid, ImageField, List, TextField } from "react-admin";

export const ServiceCategoryList = () => (
  <List sort={{ field: 'order', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <ImageField
        source="image_menu"
        label="Зображення в меню"
        sx={{
          "& img": { maxWidth: 50, maxHeight: 50, margin: "-1px!important" },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <ImageField
        source="image_details"
        label="Зображення в деталях послуги"
        sx={{
          "& img": { maxWidth: 50, maxHeight: 50, margin: "-1px!important" },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <ImageField
        source="image_main_page"
        label="Зображення на головній сторінці"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <TextField source="name" label="Назва послуги" />
      <TextField source="url" label="Посилання на послугу" />
      <TextField source="meta_title" fullWidth label="Метадані title" />
      <TextField
        source="meta_description"
        fullWidth
        label="Метадані description"
      />
      <TextField source="order" fullWidth label="Порядок" defaultValue={0} />
    </Datagrid>
  </List>
);

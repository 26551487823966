import { Edit, SimpleForm, TextInput } from "react-admin";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";

export const BrandsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled fullWidth label="ID бренду" />
      <CloudinaryInput source="image" label="Зображення бренду" />
    </SimpleForm>
  </Edit>
);

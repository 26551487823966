import { BooleanField, Datagrid, ImageField, List, NumberField } from "react-admin";

export const CertificatesList = () => (
  <List sort={{ field: "order", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <ImageField
        source="image"
        label="Зображення сертифікату"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <BooleanField source="is_show_main" label="Відображати на головній сторінці" />
      <NumberField source="order"label="Порядок"/>
    </Datagrid>
  </List>
);

import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useFormContext, useWatch } from "react-hook-form";

const MyReferenceInput = () => {
  const { control } = useFormContext();
  const { category_id } = useWatch({ control });

  return (
    category_id && (
      <ReferenceInput
        source="sub_category_id"
        reference="prices_sub_category"
        filter={{ category_id }}
      >
        <SelectInput
          optionText="sub_category"
          fullWidth
          label="Підкатегорія послуги"
        />
      </ReferenceInput>
    )
  );
};

export const PricesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" fullWidth label="Id" disabled />
      <RichTextInput source="title" fullWidth label="Послуга" />
      <ReferenceInput source="category_id" reference="prices_category">
        <SelectInput
          optionText="category"
          fullWidth
          label="Категорія послуги"
        />
      </ReferenceInput>
      <MyReferenceInput />
      <NumberInput
        source="price"
        fullWidth
        label="Ціна послуги"
        defaultValue={0}
      />
      <NumberInput source="order" fullWidth label="Порядок" defaultValue={0} />
    </SimpleForm>
  </Edit>
);
